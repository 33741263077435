import React from "react";
import style from "./style.module.css";

function index(props) {
  return (
    <div className={style.card}>
      <div className={style.img}>
        <img
          src={props.backgroundUrl}
          alt={props.title}
          width={props.width}
          height={props.height}
        />
      </div>
      <div className={style.texts}>
        <div className={style.descDiv}>
          {props.title && <p className={style.title}>{props.title}</p>}
          <p className={style.description}>{props.content}</p>
        </div>
      </div>
    </div>
  );
}

export default index;
