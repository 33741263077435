import createImageUrlBuilder from "@sanity/image-url";
import ProvincesMaroc from "assets/ProvincesMaroc/ProvincesMaroc.json";
import { createClient } from "next-sanity";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import AideCards from "SanityComponents/CreditImmobilierComponents/AideCards/AideCard";
import EstimationImmobilierCards from "SanityComponents/EstimationEmmobilier/EstimationImmobilierCards";
import EstimationImmobilierThreeButtons from "SanityComponents/EstimationEmmobilier/EstimationImmobilierThreeButtons";
import EstimationImmobilierTitleOneTexte from "SanityComponents/EstimationEmmobilier/EstimationImmobilierTitleOneTexte";
import EstimationImmobilierTitleTwoTextes from "SanityComponents/EstimationEmmobilier/EstimationImmobilierTitleTwoTextes";
import Footer from "SanityComponents/Footer/Footer";
import ThreeGuideButtons from "SanityComponents/GuideDuCredit/ThreeGuideButtons";
import LpPrixImmobilier from "SanityComponents/LandingPage/LpPrixImmobilier";
import ThreeButtons from "SanityComponents/LandingPage/ThreeButtons";
import Question from "SanityComponents/Question/Question";
import SanityHeadSeo from "../sanity/Components/SanityHeadSeo/SanityHeadSeo";
import EstimationHeader from "../SanityComponents/CreditImmobilierComponents/EstimationHeader";
import EstimationHeaderNew from "../SanityComponents/CreditImmobilierComponents/EstimationHeaderNew";

import SubHeader from "../SanityComponents/SubHeader/SubHeader";
import style from "./style.module.css";
import FAQ from "SanityComponents/FAQ/FAQ";
import EstimationImmobilier from "SanityComponents/EstimationImmobilier";
import Cards from "SanityComponents/LandingPage/Cards";
import LinksComponent from "SanityComponents/LocationAnnoncesLp/LinksComponent";
import ImageTitleSubTitleBtnFlex from "SanityComponents/ImageTitleSubTitleBtnFlex";
import WhatssapComponent from "SanityComponents/WhatssapComponent";

const client = createClient({
  apiVersion: "2023-01-01",
  dataset: "production" || "production",
  projectId: "n2i0cmq4" || "",
  useCdn: false,
});

function urlFor(source) {
  return createImageUrlBuilder(client).image(source);
}

export function EstimationImmobiliere(props: {
  data: any | null;
  page?: any | null;
}) {
  const { t, lang } = useTranslation("LandingPageEstimation");
  const { data } = props;
  return (
    <div className={style.header}>
      {data?.content.map((item, key) => {
        if (item._type == "lpheader") {
          return (
            <>
              {props.page == "vendre-rapidement" ||
              props.page === "new-home-page" ? (
                <EstimationHeaderNew
                  title={item.titre ? item.titre[lang] : ""}
                  subtitle={item.soustitre ? item.soustitre[lang] : null}
                  overlay={item.overlay_image}
                  content={item.content}
                  buttonText={item.button[lang]}
                  button_link={item.button_link}
                  backgroundUrl={urlFor(item.background_image)
                    .format("webp")
                    .auto("format")
                    .fit("clip")
                    .auto("format")
                    .toString()}
                  annoncesSearchBar={
                    item.SearchBarAnnonces ? item.SearchBarAnnonces : null
                  }
                  secondeSubtitle={
                    item.secondsoustitre ? item.secondsoustitre[lang] : ""
                  }
                  t={t}
                  lang={lang}
                  searchBar={item.SearchBar ? item.SearchBar : null}
                  vendre={item.titre["fr"] === "Vendez vite et bien"}
                  isPrixImmoSection={
                    item.titre["fr"] === "Prix de l'immobilier"
                  }
                  isAgentImmo={
                    item.titre["fr"] === "Vous êtes agent immobilier ?"
                  }
                />
              ) : (
                <EstimationHeader
                  title={item.titre ? item.titre[lang] : ""}
                  subtitle={item.soustitre ? item.soustitre[lang] : null}
                  overlay={item.overlay_image}
                  content={item.content}
                  buttonText={item.button[lang]}
                  button_link={item.button_link}
                  backgroundUrl={urlFor(item.background_image)
                    .format("webp")
                    .auto("format")
                    .fit("clip")
                    .auto("format")
                    .toString()}
                  annoncesSearchBar={
                    item.SearchBarAnnonces ? item.SearchBarAnnonces : null
                  }
                  secondeSubtitle={
                    item.secondsoustitre ? item.secondsoustitre[lang] : ""
                  }
                  t={t}
                  lang={lang}
                />
              )}
            </>
          );
        } else if (item._type == "WhatssapComponent") {
          return (
            <WhatssapComponent
              number={item?.numberPhone ? item.numberPhone : null}
              message={item?.message ? item.message[lang] : null}
            />
          )
        } else if (item._type == "ImageTitleSubTitleBtnFlex") {
          return (
            <ImageTitleSubTitleBtnFlex
              title={item.title ? item.title[lang] : ""}
              subtitle={item.subtitle ? item.subtitle[lang] : ""}
              buttonText={item.titre_button ? item.titre_button[lang] : ""}
              button_link={item.button_link}
              image={
                item.mainImage
                  ? urlFor(item.mainImage)
                      .format("webp")
                      .auto("format")
                      .fit("clip")
                      .auto("format")
                      .toString()
                  : null
              }
              lang={lang}
              isReverse={item.title["fr"] === "Prix de l'immobilier"}
              bgWhite={item.title["fr"] === "Vous êtes agent immobilier ?"}
            />
          );
        } else if (item.LandingPageCards) {
          return (
            <div key={key}>
              {item.LandingPageCards && item.LandingPageCards.length > 0 ? (
                <>
                  <div className={style.LpcardsPadding}>
                    {item.LandingPageCards.map((elem, index) => {
                      return (
                        <div key={index}>
                          {elem && (
                            <Cards
                              titre={elem.titre[lang]}
                              texte={elem.text[lang]}
                              button_text={elem.button_text[lang]}
                              lien1={elem.lien1 ? elem.lien1[lang] : null}
                              href1={elem.href1}
                              lien2={elem.lien2 ? elem.lien2[lang] : null}
                              href2={elem.href2}
                              button_link={elem.button_link}
                              image={
                                elem.mainImage
                                  ? urlFor(elem.mainImage)
                                      .format("webp")
                                      .auto("format")
                                      .fit("clip")
                                      .auto("format")
                                      .toString()
                                  : null
                              }
                              t={t}
                              lpHome={true}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </div>
          );
        } else if (item?.AnnoncesLpLinksArray?.length > 0) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className={style.linksContainer}
              >
                <LinksComponent data={item.AnnoncesLpLinksArray} lang={lang} />
              </div>
            </div>
          );
        } else {
          return (
            <div className={style.contentContainer}>
              {item._type == "SubHeaderWithLinks" && (
                <SubHeader
                  key={key}
                  title={item.titre ? item.titre[lang] : ""}
                  subTitle={item.subTitle ? item.subTitle[lang] : ""}
                  link_text1={item.link_text1 ? item.link_text1[lang] : null}
                  link1={item.link1 ? item.link1 : null}
                  outext={item.outext ? item.outext[lang] : null}
                  link_text2={item.link_text2 ? item.link_text2[lang] : null}
                  link2={item.link2 ? item.link2 : null}
                  rest_subTitle={
                    item.rest_subTitle ? item.rest_subTitle[lang] : ""
                  }
                  t={t}
                />
              )}
              {item.AnnoncesCards &&
                item.AnnoncesCards?.AnnoncesCards?.length > 0 && (
                  <div className={style.annoncescardContainer}>
                    {item.AnnoncesCards?.titre && (
                      <div className={style.cardTitle}>
                        <p className={style.title}>
                          {item.AnnoncesCards?.titre[lang]}
                        </p>
                      </div>
                    )}

                    <div className={style.annoncescards}>
                      {item.AnnoncesCards?.AnnoncesCards?.map((elem, index) => {
                        return (
                          <>
                            {elem && (
                              <EstimationImmobilierCards
                                content={
                                  elem.subtitle ? elem.subtitle[lang] : ""
                                }
                                overlay={elem.overlay_image}
                                // content={elem.text[lang]}
                                backgroundUrl={urlFor(elem.mainImage)
                                  .format("webp")
                                  .auto("format")
                                  .fit("clip")
                                  .auto("format")
                                  .toString()}
                                width={elem.width ? elem.width : elem.width}
                                height={elem.height ? elem.height : elem.height}
                              />
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              {item._type == "GuideThreeButtons" && (
                <>
                  <ThreeGuideButtons
                    button1={item.button1 ? item.button1[lang] : ""}
                    link_button1={
                      item.link_button1 ? item.link_button1[lang] : ""
                    }
                    button2={item.button2 ? item.button2[lang] : ""}
                    link_button2={
                      item.link_button2 ? item.link_button2[lang] : ""
                    }
                    button3={item.button3 ? item.button3[lang] : ""}
                    link_button3={
                      item.link_button3 ? item.link_button3[lang] : ""
                    }
                    lang={lang}
                  />
                </>
              )}
              {item._type == "Faq" && (
                <>
                  <FAQ data={item} lang={lang} />
                </>
              )}
              {item._type == "ThreeButtons" && (
                <>
                  <ThreeButtons
                    btn1={item.titre_button1 ? item.titre_button1[lang] : ""}
                    btn2={item.titre_button2[lang]}
                    btn3={item.titre_button3[lang]}
                    provinces={ProvincesMaroc}
                    t={t}
                  />
                </>
              )}
              {item._type == "TitleThreeButtons" && (
                <EstimationImmobilierThreeButtons
                  titre={item.titre ? item.titre[lang] : ""}
                  btn1={item.titre_button1 ? item.titre_button1[lang] : ""}
                  link1={item.button_link1}
                  btn2={item.titre_button2[lang]}
                  link2={item.button_link2}
                  btn3={item.titre_button3[lang]}
                  link3={item.button_link3}
                />
              )}
              {item._type == "TitleTwoTextes" && (
                <EstimationImmobilierTitleTwoTextes
                  title={item.titre ? item.titre[lang] : null}
                  texte1={item.text ? item.text[lang] : null}
                  texte2={item.text2 ? item.text2[lang] : null}
                />
              )}
              {item._type == "AddExampleListings" && (
                <>
                  {item.title[lang]}
                </>
              )}
              {item._type == "TitleOneTextes" && (
                <EstimationImmobilierTitleOneTexte
                  title={item.titre ? item.titre[lang] : null}
                  texte1={item.text ? item.text[lang] : null}
                />
              )}
              {item._type == "EstimationImmobilier" && (
                <div className={style.containerEstiImmo}>
                  <EstimationImmobilier
                    title={item.titre ? item.titre[lang] : ""}
                    text={item.text ? item.text[lang] : ""}
                    buttonText={
                      item.button_text ? item.button_text[lang] : null
                    }
                    image={urlFor(item.mainImage)
                      .format("webp")
                      .auto("format")
                      .fit("clip")
                      .auto("format")
                      .toString()}
                    button_link={item.button_link ? item.button_link : null}
                    color="white"
                    text_color={item.text_color ? item.text_color[lang] : null}
                  />
                </div>
              )}
              {item._type == "EstimationImmobilierComponent" && (
                <div>
                  <Question
                    title={item.titre ? item.titre[lang] : ""}
                    text={item.text ? item.text[lang] : null}
                    buttonText={
                      item.button_text ? item.button_text[lang] : null
                    }
                    image={urlFor(item.mainImage)
                      .format("webp")
                      .auto("format")
                      .fit("clip")
                      .auto("format")
                      .toString()}
                    button_link={item.button_link ? item.button_link : null}
                    color="black"
                    blockquote={true}
                  />
                </div>
              )}
              {item.card && (
                <>
                  {item.card?.AideCards?.length > 0 && (
                    <div className={style.annoncescardContainer}>
                      {item.card?.titre && (
                        <div className={style.cardTitle}>
                          <p>
                            {item.card?.titre[lang]}
                            {/* Comment pouvons-nous{" "}
                                  <span style={{ fontWeight: "500" }}>vous aider</span> ? */}
                          </p>
                        </div>
                      )}

                      <div className={style.annoncescards}>
                        {item.card?.AideCards?.map((elem, index) => {
                          return (
                            <>
                              {elem && (
                                <>
                                  {/* <EstimationImmobilierCards
                                    title={elem.titre[lang]}
                                    content={elem.text ? elem.text[lang] : ""}
                                    overlay={elem.overlay_image}
                                    backgroundUrl={urlFor(elem.mainImage)
                                      .format("webp")
                                      .auto("format")
                                      .fit("clip")
                                      .auto("format")
                                      .toString()}
                                  /> */}

                                  <AideCards
                                    title={elem.titre[lang]}
                                    subtitle={
                                      elem.soustitre ? elem.soustitre[lang] : ""
                                    }
                                    overlay={elem.overlay_image}
                                    content={elem.text[lang]}
                                    backgroundUrl={urlFor(elem.mainImage)
                                      .format("webp")
                                      .auto("format")
                                      .fit("clip")
                                      .auto("format")
                                      .toString()}
                                    width={elem.width ? elem.width : elem.width}
                                    height={
                                      elem.height ? elem.height : elem.height
                                    }
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
              {item._type == "SanityHeadSeo" && (
                <SanityHeadSeo
                  key={key}
                  title={item.titre ? item.titre[lang] : ""}
                  ogTitle={item.titre ? item.titre[lang] : ""}
                  description={item.description ? item.description[lang] : ""}
                  ogDescription={item.description ? item.description[lang] : ""}
                  canonicalUrl={item.canonicalUrl}
                  ogTwitterImage={
                    item.mainImage
                      ? urlFor(item.mainImage)
                          .width(250)
                          .height(250)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()
                      : null
                  }
                  ogType={item.ogType}
                  ogImageUrl={
                    item.mainImage
                      ? urlFor(item.mainImage)
                          .width(250)
                          .height(250)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()
                      : null
                  }
                  structuredData={null}
                >
                  <></>
                </SanityHeadSeo>
              )}
              {item._type == "LpPrixImmobilier" && (
                <div className={style.simulez}>
                  <LpPrixImmobilier
                    title={item.titre ? item.titre[lang] : ""}
                    texte={item.texte[lang]}
                    buttonText={item.button_text ? item.button_text[lang] : ""}
                    button_link={item.button_link}
                    forVendre={item.titre["fr"]}
                  />
                </div>
              )}
              {item._type == "SanityFooter" && (
                <>
                  <div className={style.footer}>
                    <div className={style.footerDesk}>
                      <Footer
                        propos={item.propos[lang]}
                        proposText={item.text ? item.text[lang] : ""}
                        donnee={item.donnees[lang]}
                        donneeText={item.textdonnee[lang]}
                        technologie={item.technologie[lang]}
                        technologieText={item.texttechnologies[lang]}
                        logo={urlFor(item.agenz_logo)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()}
                        facebook={urlFor(item.facebook_logo)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()}
                        instagram_logo={urlFor(item.instagram_logo)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()}
                        linked_in={urlFor(item.linkedin_logo)
                          .format("webp")
                          .auto("format")
                          .fit("clip")
                          .auto("format")
                          .toString()}
                        socialMediaText={item.socialMediatext}
                        copyRightText={item.permission_text}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}
